


export const menuOption = [
	{Id:0,
	 pageName: "home", 
	 optionDisplay: "Home"
	},
	{Id:1,
	 pageName: "about", 
	 optionDisplay: "About"
	},
	{Id:2,
	 pageName: "service", 
	 optionDisplay: "Our Services"
	},
	{Id:3,
	 pageName: "portfolio", 
	 optionDisplay: "Portfolios"
	},
	{Id:4,
	 pageName: "testimonials", 
	 optionDisplay: "Testimonials"
	},
	{Id:5,
	 pageName: "contact", 
	 optionDisplay: "Contact Us"
	}
];


export const services = [
	{serviceName: "Flat Bed Towing",
	 serviceDetail: "We provide towing services SHORT and LONG distance from anywhere to any destination for 24/7 with a flat bed tow truck to secure your vehicle from damage."
	},
	{serviceName: "Special Equipment Towing",
	 serviceDetail: "We help tow your vehicle that requires special equipments."
	},
	{
	 serviceName: "Low Profile Towing",
	 serviceDetail: "We help tow your vehicle that has very low floor to protect the body/bumpers from damage."
	},
	{serviceName: "Lock Out",
	 serviceDetail: "We open the door for you if you left the key inside your vehicle."
	},
	{serviceName: "Jump Start",
	 serviceDetail:  "We help provide a jump start to your vehicle if it won't start."
	},
	{serviceName: "Replace battery",
	 serviceDetail: "We help replace the malfunction battery of your vehicle."
	},
	{serviceName: "Flat Tire",
	 serviceDetail:  "We fix any flat tire or replace your  of your vehicle."
	},
	{serviceName: "Fuel Delivery",
     serviceDetail:	"We deliver fuel for your vehicle as you run out of fuel to get you get to destination."
	},
	{serviceName: "Stuck Calls",
	 serviceDetail:  "Stuck Calls."
	},
	{serviceName: "Vehicle Recovery",
	 serviceDetail: "We help recover your vehicle for something unfortunate happens."
	},
	{serviceName: "And Many Services",
	 serviceDetail: "Please call us, so we know what we can help."
	}
];


export const portfolioList = [
	{imageName: "IMG-20240510-WA0021.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0022.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0023.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0024.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0025.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0030.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0031.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0032.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0033.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0034.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0035.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0036.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0037.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0038.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0039.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0040.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0041.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0042.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0043.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240510-WA0044.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0003.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0004.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0005.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0006.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0007.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0008.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0009.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0010.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0011.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0012.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0013.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0014.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0015.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0016.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0017.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0018.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0019.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0020.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0021.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0022.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0023.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0024.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0025.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0026.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0027.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0028.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240511-WA0029.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240522-WA0024.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240522-WA0025.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240522-WA0026.jpg",
	 altDisplay: "This is what we have done"
	},
	{imageName: "IMG-20240522-WA0027.jpg",
	 altDisplay: "This is what we have done"
	}
];

