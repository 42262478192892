import { useFormStatus } from 'react-dom';
import logo from './logo.svg';
import {useState, useEffect} from "react";
import {menuOption, services, portfolioList} from "./AppData.js";


const serviceList = services.map(		
	serviceList => <><img src="check.png" width="30" height="30" alt=""/> {serviceList.serviceName}<br/></>
);


export function Home() {
	const [togglePage, setTogglePage] = useState(0);
	
	const divStyle = {
		width:"250px",
		borderRadius: "8px",
		backgroundColor:"#ffffff",
		marginTop: "4px",
		color:"black",
		cursor:"pointer"
	};
	
	const switch2Contact = function() {
		setTogglePage(togglePage ^ 1);		
	}
	
	if (togglePage === 0) {
		return (
			<center>		
				<img src="home.jpg" className="cls_imageFlash" alt="Home"/>
				<div className="cls_homeService">
					<h3>Our services:</h3>
					<b>{serviceList}</b>
					<h3>Contact us to quote:</h3>
					<a href="tel:734-720-2188" title="Call us now!!" target="_self"><img src="phone.png" width="250" height="50" /></a>
					<br/>
					<div onClick={switch2Contact} style={divStyle}><img src="email.png" width="250" height="50"/></div>
					<br/>
				</div>
			</center>
		);
	} else {
		return (
			<>
				<Contact isSubmit={0} />
			</>
		);
	}
}


export function About() {
  return (
	<center>
    <div className="cls_about">
	
		<h2>About Us</h2>
		<p>We take pride in providing reliable and efficient towing services across the major cities of Michigan. With years of experience in the business, we understand your stress and inconvenience that comes with unexpected vehicle breakdowns or accidents. That's why we're committed to providing prompt assistance and exceptional customer service to our clients whenever they need it most.</p>

		<h2>Our Mission:</h2>
		<p>Our mission is simple - to offer professional towing solutions and related roadside services that prioritize the safety and satisfaction of our customers. Whether you're stranded on the side of the road or need assistance with vehicle recovery after an accident, our team is dedicated to delivering fast, dependable service with a focus on getting you back on the road safely.</p>

		<h2>Why Choose Us:</h2>
		<blockquote>
			<p>- <b>Prompt Response</b>: We know that emergencies can happen at any time, which is why we offer 24/7 emergency towing services. You can count on us to arrive quickly and handle your situation with care and efficiency.</p>
			<p>- <b>Experienced Team</b>: Our team of skilled drivers and technicians are fully trained and equipped to handle a wide range of towing needs, from light-duty to heavy-duty towing.</p>
			<p>- <b>Extensive Coverage</b>: With our fleet of state-of-the-art tow trucks and strategically located depots, we're able to serve customers in major cities across Michigan, including some areas in Toledo, Ohio.</p>
			<p>- <b>Customer Satisfaction</b>: We prioritize customer satisfaction above all else. From the moment you contact us to the completion of the towing service, we strive to ensure that your experience with us is positive and stress-free.</p>
		</blockquote>
		
		<h2>Service Areas:</h2>

		<p>Our services are available in all major cities across Michigan.</p>
		<blockquote>
		- Allen Park<br/>
		- Ann Arbor<br/>
		- Brownstown<br/>
		- Canton<br/>
		- Detroit<br/>
		- Grand Rapids<br/>
		- Warren<br/>
		- Sterling Heights<br/>		
		- Lansing<br/>
		- Flint<br/>
		- Dearborn<br/>
		- Garden City<br/>
		- Wayne<br/>
		- Westland<br/>
		- Woodhaven<br/>
		- Livonia<br/>
		- Troy<br/>
		- Other Cities<br/>
		</blockquote>
		
		<h2>Contact Us:</h2>

		<p>Don't let a vehicle breakdown ruin your day. When you need reliable towing services in Michigan, trust the experts at Charlie's Towing Service. Contact us today to learn more about our services or to request assistance.</p>
		<br/><br/><br/><br/>
		</div>
		</center>
  );
}


export function Service() {
	const  cls_innerService = {
		backgroundColor:"#000000",
		opacity:"0.7",
		position:"relative",
		top:"5%",
		left:"2%",
		width:"90%",
		height:"600px",
		paddingLeft:"30px",
		borderRadius: "8px"
	};
	
	return (
		<center>
		<div className="cls_serviceList" style={{backgroundImage: "url(IMG-20240511-WA0017.jpg)"}}>
			<div style={cls_innerService}>
				<br/>
				<h2><nobr>We provide services:</nobr></h2>
				<br/>
					{serviceList}
				<br/>
				<br/><br/>
			</div>
		</div>
		</center>
	);	
}


export function Portfolio() {
	const [count, setCount] = useState(0);
	let maxCounter = portfolioList.length-1;
	
	function imageFlipper() {		
		setCount((count < maxCounter) ? (count+1) : 0);
	};	
		
	function animationFunct() {
		setTimeout(imageFlipper, 5000);		
	}
	
	useEffect(animationFunct);	
	
	return (
		<center>
			<div className="cls_portfolio">
				<img src={portfolioList[count].imageName} alt={portfolioList[count].altDisplay}  className="cls_imagePortfolio"/>							
			</div>
		</center>
	);	
}


export function Testimonial(props) {

	return (
		<center>
		<iframe src="https://www.chamberofcommerce.com/business-directory/michigan/westland/towing-service/2016144668-charlie-towing-llc"></iframe>
		</center>
	);
}


export function Contact(props) {
	const submitStatus = useFormStatus();
	const [submitToggle, toggleSwith] = useState(props.isSubmit);
	
	const processForm = async function(event) {
		// Prevent the browser from reloading the page
		event.preventDefault();

		// Read the form data
		const formTarget = event.target;
		const formData = new FormData(formTarget);
		console.log(formData);
		const formJson = Object.fromEntries(formData.entries());
		console.log(formJson);
		/*
		let fetchData = {
				  method: "post",
				  headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				  },
				  body: JSON.stringify(formJson)
		};
		*/
		
		//let responseObj = await fetch("http://localhost/MyStock/ProcessEmail.php", fetchData);
		let stringifiedFormJSon = JSON.stringify(formJson)
		let responseObj = await fetch("ProcessEmail.php?package=" + stringifiedFormJSon);
		let responseText = await responseObj.text();

		if (responseObj.ok) {
			// Handle success response
			console.log(responseText);			
			toggleSwith(1);
		} else {
			// Handle error response
			console.log("Fail");
		}
	}
	
	
	if (submitToggle === 0) {
		return (
		 
		<div className="cls_form" 
					style={{backgroundImage: "url(IMG-20240511-WA0029.jpg)", 
							backgroundPosition: "center",
							backgroundSize: "cover",
							backgroundRepeat: "no-repeat"
							}}>
			<br/><br/><br/><br/>
			<form method="post" onSubmit={processForm}>				
				<div className="cls_formRow">
					<div id="id_name" className="cls_label">
						Name* : 
					</div>
					<div className="cls_field">
						<input type="text" id="id_fullName" name="fullName" required />
					</div>
				</div>
				<div className="cls_formRow">
					<div id="id_email" className="cls_label">
						Email* : 
					</div>
					<div className="cls_field">
						<input type="text" id="id_email" name="email" required />
					</div>
				</div>
				<div className="cls_formRow">
					<div id="id_email" className="cls_label">
						Phone* : 
					</div>
					<div className="cls_field">
						<input type="text" id="id_phone" name="phone" required />
					</div>
				</div>
				<div className="cls_formRow">
					<div id="id_message" className="cls_label">
						Message* : 
					</div>
					<div className="cls_field">
						<textarea type="text" id="id_message" name="message" required ></textarea>
					</div>
				</div>
				<div >
					<div id="id_message" className="cls_label">					
						<button type="submit" className="cls_submit" disabled={submitStatus.pending}>
							{ submitStatus.pending ? "Summitting..." : "SUBMIT"}
						</button>
						
					</div>
				</div>			
			</form><br/><br/><br/>
		</div>
		  
		);
	} else {
		return (
			<div className="cls_form" >
				<center><br/><br/><br/><br/>
				<b>We will get back to you as soon as possible!!</b>
				<br/><br/><br/>
				<img src="thankyou.gif" width="100%" height="100%" alt="Thank you!!"/>
				<br/><br/><br/><br/><br/><br/><br/><br/><br/></center>
			</div>
		);
	}
}



export function Footer(props) {
	const dateObj  = new Date();
	let lYear = dateObj.getFullYear();
	
	return (
		<div>
			<center>
				<img src={logo} className="cls_builtby" alt="logo" /> built with ReactJS<br/>
				
				© 2000-{lYear} Algosmith Computing. All Rights Reserved.
			</center>
		</div>
	);
}



export function HtmlBody (props) {
	
	
	if (props.webObj.pageName === "about" ) {
		return (
			<About/>
		);
	} else if (props.webObj.pageName === "service" ) {
		return (
			<Service/>
		);
	} else if (props.webObj.pageName === "portfolio" ) {
		return (
			<Portfolio />
		);
	} else if (props.webObj.pageName === "testimonials" ) {
		return (
			<Testimonial />
		);
	} else if (props.webObj.pageName === "contact" ) {
		return (
			<Contact isSubmit={0} />
		);
	} else {
		return (
			<Home />
		);
	}
}