import {useState} from "react";
import logo from './logo.svg';
import './App.css';
import {HtmlBody} from "./WebBody.js";
import {menuOption, services} from "./AppData.js";



function App() {
	const [webObj, setWebObj] = useState(menuOption[0]);
	const [toggleFlag, setToggelFlag] = useState(0);
	const iconMenu = ["menu.png", "close.png"];
	
	var lObjIndex=0;
	const [tempObj, setTemp] = useState(lObjIndex);
	
	
	const theOption = menuOption.map(		
		theOption => <div onClick={()=>switchPage(theOption.Id)} className="cls_menuOptionList"> >  {theOption.optionDisplay}</div>
	); 
	
	
	const toggleMenu = function() {
		setToggelFlag(toggleFlag ^ 1);		
	}
	
	const switchPage = function(inObjIndex) {		
		setWebObj(menuOption[inObjIndex]);
		toggleMenu();
	}
	
	
	return (
		<center>
			<div className="App">
				<div className="App-header">
					<div className="cls_leftHeader">
						<button onClick={toggleMenu}>
							<img src={iconMenu[toggleFlag]} className="cls_iconImage" />				
						</button>					
					</div>
					<div className="cls_menulistHolder">
						{(toggleFlag) ? theOption : null}
					</div>
					<div className="cls_centerHeader">
						For Emergency Service, call us:<br/><br/>
						<a href="tel:734-720-2188" title="Call us now!!" target="_self"><img src="phone.png" width="250" height="50" /></a>
					</div>
					<div className="class_rightHeader">
						<font style={{textShadow: "2px 2px #000000"}}>Charlie <br/>Towing </font> 
					</div>
				</div>			
				<div className="App-body">
					<HtmlBody webObj={webObj} />
					<br/>
					<br/><br/><br/>
				</div>
				<a href="https://www.algosmithcomputing.com" className="cls_algosmith">
				<div className="App-footer">
					Designed by Algosmith Computing
				</div></a>			
				<br/><br/><br/><br/>
			</div>
			
			<div className="cls_bottom"><a href="tel:734-720-2188" title="(734) 720-2188" target="_self"><img src="callus.png" width="250" height="50"/></a></div>	
		</center>
	);
}

export default App;
